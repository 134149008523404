import classNames from 'classnames';
import React, {useEffect, useRef} from 'react';
import * as styles from './Typography.module.scss';

export function Typography({
                               className,
                               appearance,
                               bodySize = 'normal',
                               forBlog,
                               emphasizeFirstParagraph,
                               component: Component = 'div',
                               ...otherProps
                           }) {
    const classes = classNames(
        styles.root,
        className,
        forBlog && styles.forBlog,
        styles[`bodySize_${bodySize}`],
        emphasizeFirstParagraph && styles.emphasizeFirstParagraph
    );
    const ref = useRef();
    useEffect(() => {
        if (ref.current) {
            const images = ref.current.querySelectorAll('img');
            for (const image of images) {
                image.parentNode.classList.add(styles.scrollableContainer)
                image.parentNode.classList.add('typography-image-wrapper')
            }
        }
    }, []);

    return <Component ref={ref} className={classes} data-appearance={appearance} {...otherProps}/>;
}
