import * as styles from './Button.module.scss';
import React from 'react';
import classNames from 'classnames';
import {Link} from 'gatsby';

export function Button({component: Component = Link, appearance, size, noHover, className, ...otherProps}) {
    return <Component
        className={classNames(className, styles.root, noHover && styles.noHover)}
        data-size={size}
        data-appearance={appearance}
        {...otherProps}
    />;
}

